import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import { useSwipeable } from 'react-swipeable'

const StTestimonalsContainer = styled.div`
    min-height: 520px;
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 500px;
    }
`

const StTestimonalMobileContentCards = styled.div`
display: flex;
flex-direction: row;
max-width: 85%;
align-self: center;
overflow: hidden;

    @media (min-width: ${settings.bp.small.view}) {
        overflow: visible;
        display: none;
    };
`

const StTestimonalDesktopContentCards = styled.div`

display: none;
flex-direction: row;
max-width: 98%;
align-self: center;
overflow: hidden;

    @media (min-width: ${settings.bp.small.view}) {
        overflow: visible;
        display: flex;
    };
`

const StDots = styled.div`
display: block;
    // @media (min-width: ${settings.bp.small.view}) {
    //     display: none;
    // };
`

const StTestimonialCard = styled.div`
    max-width: 320px;
    min-width: 95%;
    min-height: 600px;
    // height: 580px;
    margin: auto;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    margin: 6px;
    background-color: #FFF;
    border-radius: 12px;
    @media (min-width: ${settings.bp.small.view}) {
        min-width: 200px;
        max-width: 350px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 12px;
        padding-bottom: 12px;
        ${(props) =>
        props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
            `
    };
    }
`

const StTestimonialsTitle = styled.div`
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 12px;
    margin-bottom: 0px;
    min-height: 78px;
    align-content: center;
    @media (min-width: ${settings.bp.small.view}) {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
`
const StTestimonialsSubtitle = styled.div`
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 8px;

    @media (min-width: ${settings.bp.small.view}) {
        margin-bottom: 0px;
        font-size: 16px;
    }
`

const StTestimonialsText = styled.div`
    text-align: left;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 2px;
    margin-top: 8px;
    color: #000000A6;
    display: flex;
    flex-grow: 100;
    max-height: 280px;
    padding: 0px 12px;
`


const Arrow = css`
transform: rotate(45deg);
    border: 2.7px solid black;
    z-index: 999;
    width: 10px;
    height: 10px;
    display: none;
    cursor: pointer;
    top: 50%;

    @media (min-width: ${settings.bp.small.view}) {
        width: 14px;
        height: 14px;
        display: block;
    }
`

const ArrowLeft = styled.div`
    ${Arrow}
    border-top: 0px;
    border-right: 0px;
    margin-right: 15px;
`

const ArrowRight = styled.div`
    ${Arrow}
    border-bottom: 0px;
    border-left: 0px;
    margin-left: 15px;
`

const ArrowContainer = styled.div`
display: flex; 
align-items: center;
width: 40px; `

const Stars = () => {
    return <div style={{ display: 'flex', margin: 'auto' }}>
        <svg width="134" height="25" viewBox="0 0 134 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0L16.0859 8.25266L24.8882 8.63729L17.993 14.1223L20.3473 22.6127L13 17.75L5.65268 22.6127L8.00695 14.1223L1.11179 8.63729L9.91413 8.25266L13 0Z" fill="#FFC332" />
            <path d="M40 0L43.0859 8.25266L51.8882 8.63729L44.993 14.1223L47.3473 22.6127L40 17.75L32.6527 22.6127L35.007 14.1223L28.1118 8.63729L36.9141 8.25266L40 0Z" fill="#FFC332" />
            <path d="M67 0L70.0859 8.25266L78.8882 8.63729L71.993 14.1223L74.3473 22.6127L67 17.75L59.6527 22.6127L62.007 14.1223L55.1118 8.63729L63.9141 8.25266L67 0Z" fill="#FFC332" />
            <path d="M94 0L97.0859 8.25266L105.888 8.63729L98.993 14.1223L101.347 22.6127L94 17.75L86.6527 22.6127L89.007 14.1223L82.1118 8.63729L90.9141 8.25266L94 0Z" fill="#FFC332" />
            <path d="M121 0L124.086 8.25266L132.888 8.63729L125.993 14.1223L128.347 22.6127L121 17.75L113.653 22.6127L116.007 14.1223L109.112 8.63729L117.914 8.25266L121 0Z" fill="#FFC332" />
        </svg>
    </div>
}

export const Testimonials = props => {
    const { data, testimonials } = props
    const maxIndex = testimonials.length - 1
    const [index, setIndex] = useState(0)
    const [indexesToShow, setIndexesToShow] = useState([0, 1, 2])
    const [autoRotate, setAutoRotate] = useState(false)
    const [mousePosition, setMousePosition] = useState(0)

    const mouseMoveHandler = event => {
        const { width } = event.target.getBoundingClientRect()
        const percentage =
            ((event.clientX - event.target.offsetLeft) / width) * 100
        setMousePosition(percentage)
    }

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler)
        }
    }, [])

    const config = {
        delta: 10,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0, // set a rotation angle
        swipeDuration: 250, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    }


    const swipeHandler = useSwipeable({
        onSwiped: eventData => {
            if (autoRotate) {
                setAutoRotate(false)
            }
            if (eventData.dir === 'Left' && eventData.deltaX < -50) {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            } else if (eventData.dir === 'Right' && eventData.deltaX > 50) {
                let newIndex = index - 1 < 0 ? maxIndex : index - 1
                setIndex(newIndex)
            }
        },
        ...config,
    })

    useEffect(() => {
        let newIndexes = []
        if (index === maxIndex) {
            newIndexes = [index, 0, 1]
        } else if (index === maxIndex - 1) {
            newIndexes = [index, index + 1, 0]
        } else {
            newIndexes = [index, index + 1, index + 2]
        }
        setIndexesToShow(newIndexes)
    }, [index])




    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            }, 8000)
            return () => clearInterval(interval)
        }
    }, [index, autoRotate])

    if (!testimonials) {
        return null
    }


    return (
        <StTestimonalsContainer
            onClick={() => {
                if (autoRotate) {
                    setAutoRotate(false)
                }
                if (mousePosition >= 50) {
                    let newIndex = index + 1 > maxIndex ? 0 : index + 1
                    setIndex(newIndex)
                } else {
                    let newIndex = index - 1 < 0 ? maxIndex : index - 1
                    setIndex(newIndex)
                }
            }}
            {...swipeHandler}
        >
            <div
                style={{
                    flex: 1,
                    marginBottom: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={`testimonial_${index}`}
            >

                <StTestimonalMobileContentCards>
                    {testimonials.map((t, tindex) => {
                        return [index, index + 1, index + 2].includes(tindex) ? (<StTestimonialCard key={`testamonial-${tindex}`}>
                            <StTestimonialsTitle>
                                {t.title}
                            </StTestimonialsTitle>
                            {Stars()}
                            <StTestimonialsText>
                                {t.text}
                            </StTestimonialsText>
                            <div style={{ flex: 1, textAlign: 'center' }}>
                                <img
                                    style={{ maxWidth: '150px', margin: 8 }}
                                    src={t.image}
                                />
                            </div>
                            <StTestimonialsSubtitle>
                                {t.productTitle}
                            </StTestimonialsSubtitle>
                        </StTestimonialCard>) : null
                    }
                    )}
                </StTestimonalMobileContentCards>
                <StTestimonalDesktopContentCards>
                    {testimonials.length > 1 && (
                        <ArrowContainer>
                            <ArrowLeft
                                onClick={() => {
                                    if (autoRotate) {
                                        setAutoRotate(false)
                                    }
                                    let newIndex =
                                        index - 1 > maxIndex ? 0 : index - 1
                                    setIndex(newIndex)
                                }}
                            />
                        </ArrowContainer>
                    )}
                    {indexesToShow.map((i, tIndex) => {
                        const t = testimonials[i]
                        return <StTestimonialCard key={`testamonial-${tIndex}`}>
                            <StTestimonialsTitle>
                                {t.title}
                            </StTestimonialsTitle>
                            {Stars()}
                            <StTestimonialsText>
                                {t.text}
                            </StTestimonialsText>
                            <a href={t.productUrl} onClick={(event) => { event.stopPropagation() }} >
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                    <img
                                        style={{ maxWidth: '150px', margin: 8 }}
                                        src={t.image}
                                    />
                                </div>
                                <StTestimonialsSubtitle>
                                    {t.productTitle}
                                </StTestimonialsSubtitle>
                            </a>
                        </StTestimonialCard>
                    })}
                    {testimonials.length > 1 && (
                        <ArrowContainer>
                            <ArrowRight
                                onClick={() => {
                                    if (autoRotate) {
                                        setAutoRotate(false)
                                    }
                                    let newIndex =
                                        index + 1 < 0 ? maxIndex : index + 1
                                    setIndex(newIndex)
                                }}
                            />
                        </ArrowContainer>
                    )}
                </StTestimonalDesktopContentCards>
            </div>
            <StDots style={{ textAlign: 'center' }}>
                {testimonials.map((t, tIndex) => {
                    return (
                        <span
                            key={`dot-${tIndex}`}
                            style={
                                [index].includes(tIndex)
                                    ? {
                                        backgroundColor: '#000',
                                        height: '7px',
                                        width: '7px',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                    }
                                    : {
                                        height: '7px',
                                        width: '7px',
                                        backgroundColor: '#D9D9D9',
                                        borderRadius: '50%',
                                        display: 'inline-block',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                    }
                            }
                        />
                    )
                })}
            </StDots>
        </StTestimonalsContainer>
    )
}
