import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import settings from '../../../kenra-storybook/global/settings'
import { useSwipeable } from 'react-swipeable'
import { DisclaimerText } from './BeatTheHeatLandingPage'

const StImagesContainer = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#FFF')};

`

const StImageMobileContentCards = styled.div`
display: flex;
flex-direction: row;
margin-left: 19px;
margin-right: 19px;
align-self: center;
overflow: hidden;
`

const StDots = styled.div`
display: block;
`

const StImageCard = styled.div`
    min-width: 100%;
    max-height: calc(100vw - 38px);
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    margin-top: 12px;
    margin-bottom: 22px;
    
`

const PreviewImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 19px;
    margin-right: 19px;
    margin-bottom: 12px;
`

const PreviewImage = styled.div`
    max-width: 33%;
    width: 33%;
    height: calc(33vw - 22px);
    margin: 0 6px;
    
`


export const ProductImages = props => {
    const { images, backgroundColor, disclaimerText } = props
    const maxIndex = images.length - 1
    const [index, setIndex] = useState(0)
    const [indexesToShow, setIndexesToShow] = useState([0, 1, 2])
    const [autoRotate, setAutoRotate] = useState(false)
    const [mousePosition, setMousePosition] = useState(0)

    const mouseMoveHandler = event => {
        const { width } = event.target.getBoundingClientRect()
        const percentage =
            ((event.clientX - event.target.offsetLeft) / width) * 100
        setMousePosition(percentage)
    }

    useEffect(() => {
        window.addEventListener('mousemove', mouseMoveHandler)
        return () => {
            window.removeEventListener('mousemove', mouseMoveHandler)
        }
    }, [])

    const config = {
        delta: 10,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0, // set a rotation angle
        swipeDuration: 250, // allowable duration of a swipe (ms). *See Notes*
        touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    }


    const swipeHandler = useSwipeable({
        onSwiped: eventData => {
            if (autoRotate) {
                setAutoRotate(false)
            }
            if (eventData.dir === 'Left' && eventData.deltaX < -50) {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            } else if (eventData.dir === 'Right' && eventData.deltaX > 50) {
                let newIndex = index - 1 < 0 ? maxIndex : index - 1
                setIndex(newIndex)
            }
        },
        ...config,
    })

    useEffect(() => {
        let newIndexes = []
        if (index === maxIndex) {
            newIndexes = [index, 0, 1]
        } else if (index === maxIndex - 1) {
            newIndexes = [index, index + 1, 0]
        } else {
            newIndexes = [index, index + 1, index + 2]
        }
        setIndexesToShow(newIndexes)
    }, [index])




    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                let newIndex = index + 1 > maxIndex ? 0 : index + 1
                setIndex(newIndex)
            }, 8000)
            return () => clearInterval(interval)
        }
    }, [index, autoRotate])

    if (!images) {
        return null
    }


    return (
        <StImagesContainer
            backgroundColor={backgroundColor}
            onClick={() => {
                if (autoRotate) {
                    setAutoRotate(false)
                }
                if (mousePosition >= 50) {
                    let newIndex = index + 1 > maxIndex ? 0 : index + 1
                    setIndex(newIndex)
                } else {
                    let newIndex = index - 1 < 0 ? maxIndex : index - 1
                    setIndex(newIndex)
                }
            }}
            {...swipeHandler}
        >
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={`image_${index}`}
            >
                <StImageMobileContentCards>
                    {images.map((t, tindex) => {
                        return [index, index + 1, index + 2].includes(tindex) ? (<StImageCard key={`image-${tindex}`}>

                            <img
                                style={{
                                    width: '100%', height: '100%', borderRadius: '12px', objectFit: 'cover',
                                    objectPosition: 'top'
                                }}
                                src={t}
                            />
                        </StImageCard>) : null
                    }
                    )}

                </StImageMobileContentCards>
                <StDots style={{
                    textAlign: 'center', marginTop: '-50px',
                    marginBottom: '19px'
                }}>
                    {images.map((t, tIndex) => {
                        return (
                            <span
                                key={`dot-${tIndex}`}
                                style={
                                    [index].includes(tIndex)
                                        ? {
                                            backgroundColor: '#000',
                                            height: '7px',
                                            width: '7px',
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            marginLeft: '2px',
                                            marginRight: '2px',
                                        }
                                        : {
                                            height: '7px',
                                            width: '7px',
                                            backgroundColor: '#D9D9D9',
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            marginLeft: '2px',
                                            marginRight: '2px',
                                        }
                                }
                            />
                        )
                    })}
                </StDots>
                <PreviewImageContainer>
                    {images.map((t, tIndex) => {
                        return (
                            <PreviewImage key={index}>
                                <img src={t} style={{
                                    width: '100%', height: '100%', borderRadius: '12px', objectFit: 'cover',
                                    objectPosition: 'top'
                                }} key={tIndex} />
                            </PreviewImage>
                        )
                    })}
                </PreviewImageContainer>
                {disclaimerText &&
                    (<DisclaimerText>{disclaimerText}</DisclaimerText>)
                }
            </div>

        </StImagesContainer>
    )
}
